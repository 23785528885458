import React, { useState, useRef, useEffect } from 'react';
import {
  HeaderComponent,
  HeaderInner,
  HeaderSections,
  MenuItem,
  MobileDropownContainer,
  MobileHeaderDrawer,
  HeaderDrawerInner,
  HeaderSection,
  HeaderExpandable,
  HeaderIcon,
  HeaderExpandedList,
  HeaderSectionList,
  HeaderSectionListItem,
  HeaderExpandedListItem,
  CartTotalContainer,
  StoreName,
  ShopingIconWrap,
} from './index.styles';
import { Arrow } from '@/assets/svgExports/Arrow';
import { HeaderArrow } from '@/assets/svgExports/HeaderArrow';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import { Usersvg } from '@/assets/svgExports/Usersvg';
import { OrderIcon } from '@/assets/svgExports/OrderIcon';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import { SupermarketIcon } from '@/assets/svgExports/SupermarketIcon';
import { UserIconDark } from '@/assets/svgExports/UserIconDark';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import ReactHamburger from '@/components/Common/ReactHamburger';
import { MobileWhatsAppComp } from '@/components/Common/WhatsAppIconComp';
import { getStoreType } from '@/utils/getStoreType';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import getOrdersDropdownList from '@/utils/orderDropdownList';
import LogoutModal from '@/components/LogoutModal';
import { commonOutsideClick } from '@/utils/isClickOutside';
import { ORDERS_DROPDOWN_LIST, STORE_CUSTOMIZATION } from '@/utils/constants';
import { deviceWidth } from '@/utils/deviceWidth';
import { executeLogout } from '@/utils/excuteLogout';
import getCustomCtaText from '@/utils/getCustomCtaText';
import useSaveHeaderHeight from '@/hooks/useSaveHeaderHeight';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { toggleRfqFormModal } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import { showMyAccount } from '@/utils/getNavList';

const Header = (props) => {
  const { primaryColor = '#ffffff', secondaryColor = '#EA5151' } = props;
  const router = useRouter();
  const [height, setHeight] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const headerRef = useRef(null);
  const ref = useRef();
  const dispatch = useDispatch();
  const [isHeaderRef, setHeaderRef] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [shopCategory, setShopCategory] = useState([]);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showDesktopDropdown, setShowDesktopDropdown] = useState(false);
  const { categories, productTags } = useSelector((state) => state.catalogReducer);
  const store = useSSRSelector((state) => state.storeReducer.store);

  const { store_info, services, theme } = store;

  const storeLogo = getActualOrDsStoreLogo(store);

  const { user, items } = useSelector((state) => ({
    user: state.userReducer,
    items: state.cartReducer.items,
  }));
  const ordersDropdownList = getOrdersDropdownList();

  const { visitorLoginFeatureStatus } = useFeatureLocks();
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;

  useSaveHeaderHeight(headerRef);

  useEffect(() => {
    setOpen(false);
    setShowLogoutConfirmation(false);
  }, [user?.isLogin]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isHeaderRef && headerRef.current) {
      setHeight(headerRef.current.offsetHeight);
      setHeaderRef(true);
    }
    if (categories && categories.length > 0) {
      const categoryTemp = categories.filter((el) => el.id !== 0);
      setShopCategory([...productTags, ...categoryTemp]);
    }
  }, [headerRef, categories]);

  function clickExpandable() {
    setOpenCategory(!openCategory);
  }

  const toggleAuthModal = () => {
    if (user?.isLogin) {
      toggleLogoutModal();
    } else router.push(getRoute(PATH.ORDERS, store_info?.domain));
  };

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function toggleShowDesktopDropdown() {
    setShowDesktopDropdown(!showDesktopDropdown);
  }

  function onHandleOrdersClick(href) {
    if (user?.isLogin && href === PATH.ORDERS && deviceWidth > 766) {
      toggleShowDesktopDropdown();
    } else {
      router.push(getRoute(PATH.ORDERS, store_info?.domain));
    }
  }
  function toggleShowDesktopDropdown() {
    setShowDesktopDropdown(!showDesktopDropdown);
  }

  const onHandleOrdersDropdown = (item, e = null) => {
    switch (item.action) {
      case ORDERS_DROPDOWN_LIST.MY_ORDERS: {
        toggleShowDesktopDropdown();
        router.push(getRoute(PATH.ORDERS, store_info?.domain));
        break;
      }
      case ORDERS_DROPDOWN_LIST.LOGOUT: {
        toggleLogoutModal();
        break;
      }
      case ORDERS_DROPDOWN_LIST.DEFAULT_PHONE: {
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClickOutside = (e) => {
    const callback = () => setShowDesktopDropdown(false);
    commonOutsideClick({ event: e, pathNo: 0, ref, callback });
  };

  const handleCartClick = () => {
    if (getStoreType({ services }) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    router.push(getRoute(PATH.CART, store_info?.domain));
  };

  function renderUserIconDesktop() {
    return (
      <>
        {deviceWidth > 766 && (
          <>
            <div className="header-7 pointer" style={{ transform: 'translateX(9px)' }}>
              <div className="mt1 ml2" onClick={() => onHandleOrdersClick(PATH.ORDERS)}>
                <UserIconDark color="#000000" width="24" height="24" />
              </div>
            </div>
            {user?.isLogin && (
              <div
                className={
                  'header-7-arrow-img ml3 pointer ' +
                  (showDesktopDropdown ? '' : 'rotate-180')
                }
                onClick={toggleShowDesktopDropdown}
              >
                <HeaderArrow color="#000000" width="10" height="10" />
              </div>
            )}
            <div
              ref={ref}
              className={
                'br2 absolute shadow-2 z-5 header-7-orderdropdown-container' +
                (isVisitorLoginVisible ? '' : '-nologin')
              }
            >
              {showDesktopDropdown &&
                ordersDropdownList.map((item) => {
                  return (
                    <>
                      {item.id === 1 && (
                        <div className="header-7-orderdropdown-container-arrow"></div>
                      )}
                      <div
                        key={item.id}
                        className={
                          'header-7-orderdropdown-container-item flex items-center z-5 ' +
                          (item.id === 3 ? 'red ' : 'black ') +
                          (item.id !== 1 && 'pointer')
                        }
                        onClick={(e) => onHandleOrdersDropdown(item, e)}
                      >
                        {item.id === 1 && (
                          <div className="mr3 mt1">
                            <Usersvg color={'black'} />
                          </div>
                        )}
                        {item.name}
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  function renderUserIconMobile() {
    return (
      <>
        <div
          className={
            'header-7-mobile-login-navigation-container ' + (user?.isLogin ? 'red' : null)
          }
          onClick={toggleAuthModal}
        >
          <Usersvg color="#000000" />
          <div className="mobile-translate-logout-text">
            <span>{user?.isLogin ? 'Logout' : 'Login'}</span>
          </div>
        </div>
        {/* <AuthModal toggleModal={toggleAuthModal} isVisible={showAuthModal} /> */}
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  /**
   * Render cartIcon
   * @return {JSX}
   */
  const _renderCartIcon = () => {
    return items.length > 0 ? (
      <div
        className="pl2 pb2 pt3 pr3 br3"
        style={{
          backgroundColor: secondaryColor,
        }}
      >
        <ShopingIconWrap>
          <SupermarketIcon color={primaryColor} width="20" height="18" />
          {items && items.length > 0 && (
            <CartTotalContainer backgroundColor={primaryColor} fontColor={secondaryColor}>
              {items.length}
            </CartTotalContainer>
          )}
        </ShopingIconWrap>
      </div>
    ) : (
      <article className="pl2 pb2 pt2 pr3 br3">
        <ShopingIconWrap>
          <SupermarketIcon color={secondaryColor} width="20" height="18" />
        </ShopingIconWrap>
      </article>
    );
  };

  const gotoStoreHome = () => {
    router.push(getRoute(PATH.PRODUCT, store_info?.domain));
  };

  return (
    <HeaderComponent
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      ref={headerRef}
    >
      <HeaderInner>
        <HeaderSections>
          <div>
            <ReactHamburger open={isOpen} setOpen={setOpen} theme={secondaryColor} />{' '}
          </div>
          {storeLogo && getVisibilityForHeader(theme?.store_logo_status) && (
            <img
              onClick={gotoStoreHome}
              className="header-logo-image ml3 pointer"
              src={storeLogo}
              alt="store logo"
            />
          )}
          {getVisibilityForHeader(theme?.store_name_status) && (
            <StoreName
              style={{
                paddingLeft: 10,
              }}
              showStoreNameOnMobile={true}
              fontColor={secondaryColor}
            >
              <span onClick={gotoStoreHome}>{store_info?.name}</span>
            </StoreName>
          )}
        </HeaderSections>
        <HeaderSections>
          <MenuItem
            margin="0 0 0 25px"
            onClick={() => router.push(getRoute(PATH.SEARCH, store_info?.domain))}
          >
            <SearchIcon color={secondaryColor} size="16" />
          </MenuItem>
          {!props?.hideCart && services?.store_flag !== 0 && (
            <MenuItem margin="0 0px 0 25px" onClick={handleCartClick}>
              {_renderCartIcon()}
            </MenuItem>
          )}
          {deviceWidth > 766 && renderUserIconDesktop()}
        </HeaderSections>
      </HeaderInner>
      {isOpen && (
        <MobileDropownContainer>
          <MobileHeaderDrawer
            paddingTop={height}
            isOpen={isOpen}
            primaryColor={primaryColor}
            secondaryColor="#000000"
          >
            <HeaderDrawerInner heightLimiter={height}>
              <HeaderSection border={true}>
                <MenuItem
                  margin="0px"
                  onClick={() => router.push(getRoute(PATH.PRODUCT, store_info?.domain))}
                >
                  <span style={{ marginRight: 24, marginLeft: 2 }}>
                    <HeaderHome color="#000000" />
                  </span>
                  Home
                </MenuItem>
              </HeaderSection>
              <HeaderExpandable heightLimiter={height}>
                <HeaderSection
                  display="flex"
                  border={false}
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={clickExpandable}
                >
                  <MenuItem margin="0px">
                    {
                      <span style={{ marginRight: 24 }}>
                        <SupermarketIcon color="#000000" width={20} height={18} />
                      </span>
                    }
                    Shop
                  </MenuItem>
                  <HeaderIcon size={16} iconPointer={openCategory}>
                    <Arrow color="#000000" />
                  </HeaderIcon>
                </HeaderSection>
                {openCategory && (
                  <HeaderExpandedList>
                    {shopCategory.map((item) => (
                      <HeaderExpandedListItem
                        key={`${item.name}-${item.id}`}
                        onClick={() =>
                          (location.href = getRoute(
                            `${PATH.PRODUCT}?cid=${item.id}`,
                            store_info?.domain
                          ))
                        }
                      >
                        {removeEmoticons(item.name)}
                      </HeaderExpandedListItem>
                    ))}
                  </HeaderExpandedList>
                )}
              </HeaderExpandable>
              <HeaderSection border={true}>
                <HeaderSectionList>
                  <HeaderSectionListItem>
                    <OrderIcon secondaryColor="#000000" />
                    <MenuItem
                      secondaryColor="#000000"
                      onClick={() => {
                        router.push(getRoute(PATH.ORDERS, store_info?.domain));
                      }}
                    >
                      {getCustomCtaText('my_orders_cta')}
                    </MenuItem>
                  </HeaderSectionListItem>
                </HeaderSectionList>
              </HeaderSection>
              {showMyAccount() ? (
                <HeaderExpandable heightLimiter={height}>
                  <HeaderSection
                    display="flex"
                    border={false}
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => setOpenMore(!openMore)}
                  >
                    <MenuItem margin="0px">More</MenuItem>
                    <HeaderIcon size={16} iconPointer={openMore}>
                      <Arrow color="#000000" />
                    </HeaderIcon>
                  </HeaderSection>
                  {openMore && (
                    <HeaderExpandedList>
                      <HeaderExpandedListItem
                        onClick={() =>
                          router.push(getRoute(PATH.MY_ACCOUNT, store_info?.domain))
                        }
                      >
                        My Account
                      </HeaderExpandedListItem>
                    </HeaderExpandedList>
                  )}
                </HeaderExpandable>
              ) : (
                <HeaderSection>
                  <HeaderSectionList>
                    <MobileWhatsAppComp secondaryColor="#000000" />
                    {deviceWidth < 767 && isVisitorLoginVisible && renderUserIconMobile()}
                  </HeaderSectionList>
                </HeaderSection>
              )}
            </HeaderDrawerInner>
          </MobileHeaderDrawer>
        </MobileDropownContainer>
      )}
    </HeaderComponent>
  );
};

export default Header;
